
.btn-red:focus, .btn-red:hover {
    color: #fff !important;
    border-color: #fd5658;
    background-color: #fd5658;
}
.promotion-content {
    margin-right: 20px;
    min-height: calc(100vh - 299px);
    .table-btn {
        padding-top: 10px;
    }
    /deep/ .el-table {
        min-height: calc(100vh - 447px);
        .el-table__body {
            tr td {
                padding: 27px 0;
            }
            .cell {
                .plan-name {
                    display: flex;
                    align-items: center;
                    /*justify-content: center;*/
                    cursor: pointer;
                    color: #1E63F1;
                    .edit-plan-iocn {
                        display: none;
                        font-size: 14px;
                        margin-left: 10px;
                    }
                    &:hover {
                        color: #1E63F1;
                        .edit-plan-iocn {
                            display: block;
                            color: #333;
                        }
                    }
                }
                .name-input {
                    display: block;
                    position: absolute;
                    top: 26px;
                    height: 18px;
                    width: 90px;
                }
                .daily-budget {
                    cursor: pointer;
                    color: #1E63F1;
                }
                .del-blue-btn {
                    cursor: pointer;
                    color: #1E63F1;
                }
            }
        }
    }
    .table-top-btn {
        border: 1px solid #DCDFE6;
        color: #333;
    }
}
/deep/ .el-drawer {
    &.rtl {
        &:focus {
            outline: 0;
        }
    }
    .el-drawer__header {
        color: #333333;
        font-size: 24px;
        padding: 40px 20px 0 60px;
        margin-bottom: 0;
        font-weight: 500;
        span[role='heading'] {
            &:focus {
                outline: 0;
            }
        }
    }
    .el-drawer__body {
        height: calc(100% - 74px);
    }
}
.custom-box {
    /deep/ .el-scrollbar__view {
        /*position: relative;*/
        min-height: 100%;
    }
}

//s关键词页面
.custom-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 60px;
    span {
        color: #989898;
        font-size: 18px;
        line-height: 1.5;
    }
    .title {
        color: #333333;
        font-size: 24px;
        padding-bottom: 20px;
        margin-top: 0;
    }
    span:nth-child(4) {
        line-height: 27px;
    }
    .box-bottom {
        /*position: absolute;*/
        /*bottom: 0;*/
        display: block;
        width: 100%;
        margin-top: 30px;
        font-size: 18px;
        color: #333333;
        text-align: right;
        /deep/ .btn-red {
            padding: 17px 45px;
            border-radius: 6px;
        }
    }
    .CustomBudget-radio {
        margin-top: 30px;
        font-size: 18px;
        color: #333333;
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #FD4446;
        background: #FD4446;
    }
    /deep/ .el-radio__inner:hover {
        border-color: #FD4446;
    }
    /deep/ .el-radio__input.is-checked+.el-radio__label {
        color: #333;
    }
    .isShowActive {
        background: #FFECC8!important;
        border-color: #ffffff!important;
    }
    .select-keyword-table {
        width: 100%;
        /*height: 100%;*/
        height: calc(100% - 80px);
        .keyword-select {
            margin-bottom: 20px;
        }
        .select-table {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            height: calc(100% - 60px);
            .is-selected-tb {
                height: 100%;
                width: 1%;
                flex: 1;
                border: 1px solid #DFDFDF;
                .table-btn {
                    padding: 10px 20px;
                    span {
                        font-size: 20px;
                        //font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                        font-weight: 500;
                        color: #333333;
                    }
                }
                /deep/ .el-table--medium td, /deep/ .el-table--medium th {
                    padding: 14px 0;
                }
                /deep/ .el-tabs {
                    height: 100%;
                    /deep/ .el-tabs__header {
                        margin: 0;
                        .el-tabs__item {
                            padding-left: 20px;
                            padding-top: 10px;
                            font-weight: 500;
                        }
                    }
                    /deep/ .el-tabs__content {
                        height: calc(100% - 55px);
                        .el-tab-pane {
                            height: 100%;
                        }
                    }
                }
                .manual-input {
                    margin: 20px;
                }
                ul.rule {
                    margin: 0;
                    padding: 0 20px 0 40px;
                    list-style-type: none;
                    li {
                        span {
                            font-size: 16px;
                            //font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        }
                    }
                }
            }
            .selected-tb {
                width: 40%;
                height: 100%;
                margin-left: 43px;
                border: 1px solid #DFDFDF;
                /deep/ .el-table--medium td, /deep/ .el-table--medium th {
                    padding: 14px 0;
                }
                .table-btn {
                    padding: 10px 20px;
                    span {
                        font-size: 20px;
                        //font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                        font-weight: 500;
                        color: #333333;
                        margin-right: 20px;
                    }
                    /deep/ .el-button {
                        padding: 7px 15px;
                    }
                }
                /deep/ .el-input__inner {
                    height: 30px;
                    line-height: 30px;
                }
            }
        }
    }
}
/deep/ .el-drawer {
    &.rtl {
        &:focus {
            outline: 0;
        }
    }
    .el-drawer__header {
        color: #333333;
        font-size: 24px;
        padding: 40px 20px 0 60px;
        margin-bottom: 0;
        font-weight: 500;
        span[role='heading'] {
            &:focus {
                outline: 0;
            }
        }
    }
    /*.el-drawer__body {*/
    /*height: calc(100% - 74px);*/
    /*}*/
}
.el-tabs {
    /deep/ .el-tabs__header {
        margin: 0 0 32px;
        .el-tabs__active-bar {
            background-color: #FD4446;
        }
        .el-tabs__nav-wrap::after {
            height: 1px;
            background-color: #DFDFDF;
        }
        .el-tabs__item {
            font-size: 20px;
            //font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #333333;
            margin-bottom: 14px;
        }
    }
}
.is-hidden {
    display: none;
}
//e关键词页面
.table-line-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
        margin-right: 20px;
    }
    .search-input {
        width: 300px;
    }
}

/deep/ .el-rate__icon {
    margin-right: 0;
}
